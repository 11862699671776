<!-- 招生系统基础配置 -- 高考录取分数线 -- 列表 -->
<template>
  <div>
    <list-template
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onAdd="$router.push('./add')"
        has-add
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      search: {},
      searchConfig: [
        {
          prop: "province_code",
          tag: 'select',
          placeholder: "筛选省份",
          options: [],
          label: 'address_name',
          value: 'province_code'
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig: [
        {prop: "address_name", label: "省份"},
        {prop: "grad_year", label: "年份"},
        {prop: "exam_type_name", label: "考试方式"},
        {prop: "direction_name", label: "学习方向"},
        {prop: "creator_name", label: "创建人"},
        {prop: "created_at", label: "创建时间"},
        {
          prop: "handle",
          label: "操作",
          width: "120rem",
          handle: true,
          render(row) {
            return ["编辑"]
          }
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
    this.searchConfig[0].options = tools.getSession('gaokaoConfig').area;
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getData() {
      let params = {...this.search, page: this.page}
      this.$_register('/api/recruit/enroll/list', {params}).then(res => {
        if(res.data.status === 0){
          let data = res.data.data
          this.tableData = data.list
          this.total = data.page.total
        }
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row, text) {
        this.$router.push('./edit?id=' + row.id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .t-search-block .el-input {
  width: auto !important;
}

.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
